.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


#minhaIndicacao {
  color: #ebb4ad;
  text-align: center;
  height: 80;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

#logoMCM {
  padding-left: 10px;
  padding-right: 10px;
}
